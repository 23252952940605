import React from "react";
import logo from "../../assets/ovive-name.svg";
import topLeftIocn from "../../assets/Group 1171275041.png";

const DeleteAccount = () => {
  return (
    <main className="relative bg-main_color pt-8 md:pt-12 pb-12">
      {/* <div className="absolute left-0 top-0">
        <img src={topLeftIocn} className="max-h-72 h-full" alt="..." />
      </div> */}
      {/* <section className="container flex justify-between items-center">
        <div className="max-w-20 md:max-w-32 mt-4 md:mt-6">
          <img src={logo} alt="..." className="" />
        </div>
      </section> */}
      <section className="container ">
        {/* <div className=" max-w-[800px] mx-auto text-center mt-10">
          <h1 className="text-custom_h6 sm:text-custom_h3 text-heading_color">
            Privacy Policy
          </h1>
          <p className="text-small2 md:text-body_copy2 text-heading_color">
            Last updated: July 2024
          </p>
        </div> */}
        <div className="rounded-2xl bg-white p-6 sm:px-16 mx-auto ">
          <h2 className="text-body_copy1_b sm:text-custom_h6 text-heading_color">
            Steps to Delete a User's Account
          </h2>
          <h6 className="mt-3 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            1. *Login to the App*
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            - User logs in with their credentials to ensure only authorized
            access to account settings.
          </p>
          <br />
          <h6 className="text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            2. *Navigate to Profile*
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            - Go to the profile section, typically accessible through the menu
            or profile icon.
          </p>
          <br />
          <h6 className="text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            3. *Select 'Delete Account'*
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            - Locate and select the "Delete Account" option under account
            settings.
          </p>
          <br />
          <h6 className="text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            4. *Confirm Deletion*
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            - Confirm the deletion action when prompted, acknowledging that the
            process is irreversible.
          </p>
          <br />
          <h6 className="text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            5. *Immediate Data Deletion*
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            - User's account and data are immediately deleted and cannot be
            recovered.
          </p>
          <br />
          <h6 className="text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            6. *Account and Data Inaccessibility*
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            - The user will lose access to their account and all associated data
            permanently.
          </p>
          <br />
        </div>
      </section>
    </main>
  );
};

export default DeleteAccount;
