import React from "react";
import logo from "../../assets/ovive-name.svg";
import topLeftIocn from "../../assets/Group 1171275041.png";

const PrivacyPolicy = () => {
  return (
    <main className="relative bg-custom-gradient-tc-pvc">
      <div className="absolute left-0 top-0">
        <img src={topLeftIocn} className="max-h-72 h-full" alt="..." />
      </div>
      <section className="container flex justify-between items-center">
        <div className="max-w-20 md:max-w-32 mt-4 md:mt-6">
          <img src={logo} alt="..." className="" />
        </div>
      </section>
      <section className="container">
        <div className="max-w-[800px] mx-auto text-center mt-10">
          <h1 className="text-custom_h6 sm:text-custom_h3 text-heading_color">
            Privacy Policy
          </h1>
          <p className="text-small2 md:text-body_copy2 text-heading_color">
            Last updated: July 2024
          </p>
        </div>
        <div className="max-w-[750px] mx-auto mt-8 md:mt-12 mb-12">
          <p className="text-small2 md:text-body_copy2 text-heading_color">
            This Privacy Policy describes Our policies and procedures on the
            collection, use, and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You. By using the Service, You agree to the collection and
            use of information in accordance with this Privacy Policy.
            <br />
            We use Your Personal Data to provide and improve the Service.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Interpretation and Definitions
          </h5>
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            Interpretation
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <br />
          <h6 className="text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            Definitions
          </h6>
          <h6 className="mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            For the purposes of this Privacy Policy:
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Account means a unique account created for You to access our Service
            or parts of our Service.
            <br />
            <br />
            Affiliate means an entity that controls, is controlled by or is
            under common control with a party, where "control" means ownership
            of 50% or more of the shares, equity interest, or other securities
            entitled to vote for election of directors or other managing
            authority.
            <br />
            <br />
            Application refers to Ovive, the software program provided by the
            Company.
            <br />
            <br />
            Company (referred to as either "the Company", "We", "Us" or "Our" in
            this Agreement) refers to Ovive
            <br />
            <br />
            Country refers to: Andhra Pradesh, India
            <br />
            <br />
            Device means any device that can access the Service such as a
            computer, a cellphone, or a digital tablet.
            <br />
            <br />
            Personal Data is any information that relates to an identified or
            identifiable individual.
          </p>
          <br />
          <h6 className="text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            Service refers to the Application.
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Service Provider means any natural or legal person who processes the
            data on behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services
            related to the Service, or to assist the Company in analyzing how
            the Service is used.
            <br />
            <br />
            Usage Data refers to data collected automatically, either generated
            by the use of the Service or from the Service infrastructure itself
            (for example, the duration of a page visit).
            <br />
            <br />
            You means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Collecting and Using Your Personal Data
          </h5>
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            Types of Data Collected
          </h6>
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            Personal Data
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Email address
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            First name and last name
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Phone number
          </p>
          <br />
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            Usage Data
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Usage Data is collected automatically when using the Service.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g., IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers, and
            other diagnostic data.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers, and other diagnostic data.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Information Collected while Using the Application
          </h5>
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            While using Our Application, in order to provide features of Our
            Application, We may collect, with Your prior permission:
          </h6>
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            Pictures and other information from your Device's camera and photo
            library
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information may be uploaded
            to the Company's servers and/or a Service Provider's server, or it
            may be simply stored on Your device. You can enable or disable
            access to this information at any time, through Your Device
            settings.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Use of Your Personal Data
          </h5>
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            The Company may use Personal Data for the following purposes:
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            To provide and maintain our Service, including to monitor the usage
            of our Service.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            To manage Your Account: to manage Your registration as a user of the
            Service. The Personal Data You provide can give You access to
            different functionalities of the Service that are available to You
            as a registered user.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            For the performance of a contract: the development, compliance, and
            undertaking of the purchase contract for the products, items, or
            services You have purchased or of any other contract with Us through
            the Service.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative
            communications related to the functionalities, products, or
            contracted services, including the security updates, when necessary
            or reasonable for their implementation.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            To provide You with news, special offers, and general information
            about other goods, services, and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless You have opted not to receive such information.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            To manage Your requests: To attend and manage Your requests to Us.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            For business transfers: We may use Your information to evaluate or
            conduct a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which Personal Data held by Us about our
            Service users is among the assets transferred.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            For other purposes: We may use Your information for other purposes,
            such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns, and to evaluate and
            improve our Service, products, services, marketing, and your
            experience.
          </p>
          <br />
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            Sharing Your Personal Data
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            We may share Your personal information in the following situations:
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            With Service Providers: We may share Your personal information with
            Service Providers to monitor and analyze the use of our Service, to
            contact You.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            For business transfers: We may share or transfer Your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            With Affiliates: We may share Your information with Our affiliates,
            in which case we will require those affiliates to honor this Privacy
            Policy. Affiliates include Our parent company and any other
            subsidiaries, joint venture partners, or other companies that We
            control or that are under common control with Us.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            With business partners: We may share Your information with Our
            business partners to offer You certain products, services, or
            promotions.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            With other users: when You share personal information or otherwise
            interact in the public areas with other users, such information may
            be viewed by all users and may be publicly distributed outside.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            With Your consent: We may disclose Your personal information for any
            other purpose with Your consent.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Retention of Your Personal Data
          </h5>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer periods.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Transfer of Your Personal Data
          </h5>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country, or other
            governmental jurisdiction where the data protection laws may differ
            from those in Your jurisdiction.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place, including the security of Your data and other personal
            information.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Delete Your Personal Data
          </h5>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Disclosure of Your Personal Data
          </h5>
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            Sharing Your Personal Data
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            If the Company is involved in a merger, acquisition, or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Law enforcement
          </h5>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g., a court or a government
            agency).
          </p>
          <br />
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            Other legal requirements
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <br />
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            Comply with a legal obligation
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Protect and defend the rights or property of the Company
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Protect the personal safety of Users of the Service or the public
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Protect against legal liability
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Security of Your Personal Data
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Children's Privacy
          </h5>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Links to Other Websites
          </h5>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third-party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            We have no control over and assume no responsibility for the
            content, privacy policies, or practices of any third-party sites or
            services.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Changes to this Privacy Policy
          </h5>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <br />
          <h5 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            Contact Us
          </h5>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <br />
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            <a href="mailto:help@Ovive.app">By email: Help@Ovive.app</a>
          </p>
        </div>
      </section>
    </main>
  );
};

export default PrivacyPolicy;
