import React from "react";
import logo from "../../assets/ovive-name.svg";
import topLeftIocn from "../../assets/Group 1171275041.png";

const TermsAndConditions = () => {
  return (
    <main className="relative bg-custom-gradient-tc-pvc">
      <div className="absolute left-0 top-0">
        <img src={topLeftIocn} className="max-h-72 h-full" alt="..." />
      </div>
      <section className="container flex justify-between items-center">
        <div className="max-w-20 md:max-w-32 mt-4 md:mt-6">
          <img src={logo} alt="..." className="" />
        </div>
      </section>
      <section className="container">
        <div className="max-w-[800px] mx-auto text-center mt-8">
          <h1 className="text-custom_h6 sm:text-custom_h3 text-heading_color">
            Terms and Conditions
          </h1>
          <p className="text-small2 md:text-body_copy2 text-heading_color">Last updated: July 28, 2024</p>
        </div>
        <div className="max-w-[750px] mx-auto mt-6 md:mt-12 mb-12">
          <p className="text-small2 text-center sm:text-left md:text-body_copy2 text-heading_color">
            Welcome to Ovive! These Terms and Conditions ("Terms", "Terms and
            Conditions") govern your relationship with Ovive ("us", "we", or
            "our") and your use of the Ovive mobile application (the "Service").
            By accessing or using the Service, you agree to be bound by these
            Terms.
          </p>
          <br />
          <h6 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            1. Acceptance of Terms
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            By accessing or using the Service, you agree to comply with and be
            bound by these Terms. If you disagree with any part of the Terms,
            then you may not access the Service.
          </p>
          <br />
          <h6 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            2. Changes to Terms
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            We reserve the right to modify or replace these Terms at any time
            without prior notice. Your continued use of the Service constitutes
            your acceptance of the updated Terms. You are advised to review
            these Terms periodically for any changes.
          </p>
          <br />
          <h6 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            3. Use of Copyrighted Images
          </h6>
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            3.1 Ownership and Sources
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            The images displayed on Ovive are created by Ovive or obtained from
            original owners or downloaded from various sources.
          </p>
          <br />
          <h6 className="text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            3.2 No Licensing or Permissions
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            We do not hold licenses for the use of these images. The primary
            purpose of displaying these images is to provide information.
          </p>
          <br />
          <h6 className="text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            3.3 Attribution and Use
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            We do not provide specific attribution to the creators of the
            images. The images are posted as part of the information presented
            on the Service.
          </p>
          <br />
          <h6 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            4. User-Generated Content
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Users are not allowed to upload, post, or share any content on the
            Service. All content, including images and information, is managed
            by Ovive.
          </p>
          <br />
          <h6 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            5. Monetization
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            The Service is currently offered for free. There are no
            advertisements, in-app purchases, or any other form of monetization
            involved with the use of copyrighted images.
          </p>
          <br />
          <h6 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            6. Geographic Scope
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            The Service is available globally via the Google Play Store, with a
            primary focus on users in India.
          </p>
          <br />
          <h6 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            7. Content Moderation and Copyright Infringement
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            If you are the owner of any copyrighted content that appears on the
            Service and believe that your rights have been infringed, please
            contact us at Help@ovive.app. We will promptly remove the content
            upon verification of your ownership.
          </p>
          <br />
          <h6 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            8. Dispute Resolution
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            Any disputes or claims related to the use of copyrighted images or
            other content on the Service should be directed to Help@ovive.app.
            We will handle all disputes in a timely and fair manner.
          </p>
          <br />
          <h6 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            9. Disclaimer
          </h6>
          <h6 className="sm:mt-2 text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            9.1 General Disclaimer
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            The Service and all content, including images, are provided on an
            "as is" basis without any warranties of any kind, either express or
            implied.
          </p>
          <br />
          <h6 className="text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            9.2 Accuracy of Information
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            We strive to provide accurate and up-to-date information, but we
            make no representations or warranties regarding the accuracy,
            completeness, or reliability of any content on the Service.
          </p>
          <br />
          <h6 className="text-small1_bold sm:text-body_copy2 font-semibold sm:font-semibold text-heading_color">
            9.3 Changes to Information
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            The information on this page and within the Service is subject to
            change at any time without prior notice. As the app is under
            development, features, functionalities, and other aspects may
            change. Users are advised to review the Terms and Conditions
            periodically for any updates.
          </p>
          <br />
          <h6 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            10. Limitation of Liability
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            To the fullest extent permitted by applicable law, Ovive shall not
            be liable for any indirect, incidental, special, consequential, or
            punitive damages, or any loss of profits or revenues, whether
            incurred directly or indirectly, or any loss of data, use, goodwill,
            or other intangible losses resulting from (i) your access to or use
            of or inability to access or use the Service; (ii) any conduct or
            content of any third party on the Service; or (iii) unauthorized
            access, use, or alteration of your transmissions or content.
          </p>
          <br />
          <h6 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            11. Governing Law
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            These Terms shall be governed and construed in accordance with the
            laws of Andhra Pradesh, India, without regard to its conflict of law
            provisions.
          </p>
          <br />
          <h6 className="sm:mt-3 text-body_copy2 font-bold sm:text-body_copy1_b text-heading_color">
            12. Contact Us
          </h6>
          <p className="text-small2 sm:text-body_copy2 text-heading_color">
            If you have any questions about these Terms, please contact us at
            Help@ovive.app.
          </p>
          <br />
        </div>
      </section>
    </main>
  );
};

export default TermsAndConditions;
